import { ComponentType, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { DetailModeEnum } from 'core/containers/FormDetailPage';
import FullscreenRoute from 'core/containers/FullscreenRoute';
import SidebarRoute from 'core/containers/SidebarRoute';

const layout = {
  side: SidebarRoute,
  full: FullscreenRoute,
} as const;

export interface RouteDef {
  readonly path: string;
  readonly component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
  readonly layout: keyof typeof layout;
  readonly mode?: DetailModeEnum;
  readonly exact?: boolean;
}

const useRenderRoutes = (routes: RouteDef[], key?: any) => {
  return useMemo(
    () =>
      routes.map((it) => {
        const C = layout[it.layout];
        return (
          <C
            exact={'exact' in it ? it.exact !== false : true}
            // @ts-ignore TODO: solve with FEC-41
            component={it.component}
            mode={('mode' in it && it.mode) || undefined}
            path={it.path}
            key={key}
          />
        );
      }),
    [routes, key]
  );
};

export default useRenderRoutes;
