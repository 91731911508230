import { RouteDef } from 'app/hooks/useRenderRoutes';
import { DetailModeEnum } from 'core/containers/FormDetailPage';
import safeLazy from 'core/functions/safeLazy';

import { projectsEntities } from './module';

export const projectRoutes: RouteDef[] = [
  // Projects
  {
    path: projectsEntities.project.urls().list,
    component: safeLazy(() => import('./pages/ProjectListPage')),
    layout: 'side',
  },
  {
    path: projectsEntities.project.urls().create,
    component: safeLazy(() => import('./pages/ProjectDetailPage')),
    layout: 'side',
    mode: DetailModeEnum.CREATE,
    exact: false,
  },
  {
    path: projectsEntities.project.urls().clone(':id'),
    component: safeLazy(() => import('./pages/ProjectDetailPage')),
    layout: 'side',
    mode: DetailModeEnum.CLONE,
    exact: false,
  },
  {
    path: projectsEntities.project.urls().detail(':id'),
    component: safeLazy(() => import('./pages/ProjectDetailPage')),
    layout: 'side',
    mode: DetailModeEnum.EDIT,
    exact: false,
  },
];
